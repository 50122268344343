import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import firebase from '../../database/config'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'fit-content',
    marginBottom: 20,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  exit: {
    cursor: 'pointer',
  },
}))

function Header(props) {
  const classes = useStyles()
  const { history } = props

  const performLogout = () => {
    firebase.auth.signOut()
      .then(() => {
        history.replace('/login')
      }, (error) => {
        alert('Il y a eu une erreur lors de la déconnexion')
        console.log(error)
      })
  }

  const redirect = () => { history.replace('/') }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography style={{ cursor: 'pointer' }} onClick={redirect} variant="h6" className={classes.title}>
            Acropole Déploiement
          </Typography>
          <ExitToAppIcon onClick={performLogout} className={classes.exit} />
        </Toolbar>
      </AppBar>
    </div>
  )
}

Header.propTypes = {
  history: PropTypes.object.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  SET_USER: (user, token) => dispatch({ type: 'SET_USER', user, token }),
})

export default connect(null, mapDispatchToProps)(Header)
