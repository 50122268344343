import React from 'react'
import {
  createStore,
  applyMiddleware,
  compose,
} from 'redux'
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history'
import thunk from 'redux-thunk'
import {
  ConnectedRouter,
  routerMiddleware,
} from 'connected-react-router'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import createRootReducer from './reducers'
import Routes from './routes'

const history = createBrowserHistory()

const store = createStore(
  createRootReducer(history),
  compose(
    applyMiddleware(
      routerMiddleware(history),
    ),
    applyMiddleware(thunk),
  ),
)

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#273580',
    },
    secondary: {
      main: '#EA6751',
    },
    table: {
      display: 'block',
      width: '100%',
      overflowX: 'auto',
    },
  },
})

const Router = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <MuiThemeProvider theme={theme}>
        <Routes />
      </MuiThemeProvider>
    </ConnectedRouter>
  </Provider>
)

export default Router
