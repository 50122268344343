import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom'
import {
  Home,
  Login,
  CustomerRequest,
} from '../pages'
import {
  Header,
} from '../components'

class App extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  route = ({ component: Component, ...rest }) => <Route {...rest} render={(props) => <Component {...props} />} />

  render() {
    const { history } = this.props
    return (
      <Router>
        <div style={{ display: 'flex', flexDirection: 'column' }} className="routes">
          <Header history={history} />
          <div style={{
            display: 'flex', alignItems: 'center', justifyContent: 'center',
          }}
          >
            <this.route exact path="/login" component={Login} />
            <this.route restricted exact path="/" component={Home} />
            <this.route restricted exact path="/tracking/:id?" component={CustomerRequest} />
          </div>
        </div>
      </Router>
    )
  }
}

export default (withRouter(App))
