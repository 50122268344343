import autosize from 'autosize'
import _ from 'lodash'

const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver

class Form {
  constructor() {
    this.observer = []
    window.addEventListener('DOMSubtreeModified', () => {
      this.do()
    }, false)
    this.do()
  }

  do = () => {
    this.textareas = document.querySelectorAll('.field.textarea textarea')
    this.inputs = document.querySelectorAll('.field.text, .field.textarea')
    if (this.inputs.length > 0) this.make(this.inputs)
    if (this.textareas.length > 0) autosize(this.textareas)
  }

  event = (input) => {
    const parent = input.closest('.field')
    if (!_.isEmpty(input.value)) parent.classList.add('completed')
    else parent.classList.remove('completed')
    input.addEventListener('focus', () => {
      parent.classList.add('focused')
    })
    input.addEventListener('blur', () => {
      parent.classList.remove('focused')
    })
  }

  make = (fields) => {
    _.map(this.observer, (o) => o.disconnect())
    _.map(fields, (field, i) => {
      this.event(field.querySelector('input, textarea'))
      this.observer[i] = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.target.nodeName === 'INPUT' || mutation.target.nodeName === 'TEXTAREA') {
            const parent = mutation.target.closest('.field')
            if (!_.isEmpty(mutation.target.value)) parent.classList.add('completed')
            else parent.classList.remove('completed')
          }
        })
      })
      this.observer[i].observe(field, {
        attributes: true,
        childList: true,
        subtree: true,
        characterData: true,
      })
    })
  }
}

export default Form
