const firebase = require('firebase')
require('firebase/auth')
require('firebase/database')

const firebaseConfig = {
  apiKey: 'AIzaSyAu-6xpDKm4HJMN2IX4jNIzsKzaIWUOlrI',
  authDomain: 'coi-back-8dbf9.firebaseapp.com',
  databaseURL: 'https://coi-back-8dbf9.firebaseio.com',
  projectId: 'coi-back-8dbf9',
  storageBucket: 'coi-back-8dbf9.appspot.com',
  messagingSenderId: '655663537175',
  appId: '1:655663537175:web:6152ef315d31afb637d052',
  measurementId: 'G-95772ZYFP0',
}

firebase.initializeApp(firebaseConfig)
const database = firebase.database()
const auth = firebase.auth()

export default {
  auth,
  database,
}
