import React from 'react'
import ReactDOM from 'react-dom'
import '@babel/polyfill'
import Router from './redux/router'
import './assets/styles/index.scss'
import './assets/js'

ReactDOM.render(
  <Router />,
  document.getElementById('root'),
)
