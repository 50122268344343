import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import user from '../pages/login/api/reducers'

const AppReducer = (history) => combineReducers({
  router: connectRouter(history),
  user,
})

export default AppReducer
