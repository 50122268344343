/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import DeleteIcon from '@material-ui/icons/Delete'
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import InputLabel from '@material-ui/core/InputLabel'
import _ from 'lodash'
import moment from 'moment'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import firebase from '../../database/config'

class CustomerRequest extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.initialState = {
      Compagny: '',
      Email: '',
      Name: '',
      Phone: '',
      Postal: '',
      City: '',
      Date: '',
      Paid: '',
      Status: '',
      project: '',
      plan: '',
      cgv: '/',
      subscription: true,
      withPresentation: false,
      installationCosts: false,
      onlyCgv: false,
      installationAmount: '100',
      dateCgv: '',
      success: {
        operation: false,
        message: '',
      },
      error: {
        operation: false,
        message: '',
      },
      Users: [],
      checked: false,
      Roles: [
        {
          id: 1,
          name: 'Utilisateur',
        },
        {
          id: 2,
          name: 'Administrateur',
        },
      ],
      stripe: [],
    }
    this.state = this.initialState
  }

  componentDidMount() {
    const { match, history } = this.props
    const { id } = match.params
    this.handleAddUser()
    if (id === undefined) {
      firebase.auth.onAuthStateChanged((user) => {
        if (user) {
          fetch(`${process.env.REACT_APP_BASE_URL}/stripe`, {
            method: 'GET',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
          })
            .then((response) => response.json())
            .then((res) => {
              this.setState({
                stripe: res,
                project: res.find((e) => e.active).name,
                plan: res[0].plans[0].id,
              })
            })
        } else {
          history.replace('/login')
        }
      })
    }
    if (id === undefined) this.setState({ checked: true })
    if (id !== undefined) {
      firebase.auth.onAuthStateChanged((user) => {
        if (user) {
          const trackingRef = firebase.database.ref(`request/${id}`)
          trackingRef.once('value')
            .then((snap) => {
              const data = snap.val()
              this.setState({
                checked: true,
                Compagny: data.compagny,
                Email: data.email,
                Name: data.name,
                Phone: data.phone,
                installationCosts: data.installationCosts,
                Postal: data.postal,
                City: data.city,
                Date: data.date,
                Paid: data.paid,
                Status: data.status,
                Users: data.users,
                subscription: data.subscription,
                cgv: data.cgv,
                dateCgv: data.dateCgv,
                withPresentation: data.withPresentation,
              })
              fetch(`${process.env.REACT_APP_BASE_URL}/stripe`, {
                method: 'GET',
                headers: new Headers({
                  'Content-Type': 'application/json',
                }),
              })
                .then((response) => response.json())
                .then((res) => {
                  this.setState({
                    stripe: res,
                    project: data.project,
                    plan: data.plan,
                  })
                })
            })
            .catch((error) => {
              console.log(error)
              alert('Erreur serveur')
              history.replace('/')
            })
        } else {
          history.replace('/login')
        }
      })
    }
  }

  componentDidUpdate(_prevProps, prevState) {
    const {
      project,
      stripe,
      withPresentation,
      installationCosts,
      subscription,
    } = this.state
    const {
      project: projectOld,
      installationCosts: installationCostsOld,
      withPresentation: withPresentationOld,
      subscription: subscriptionOld,
    } = prevState
    if (!_.isEqual(installationCosts, installationCostsOld) || !_.isEqual(withPresentation, withPresentationOld)) {
      if (withPresentation && !installationCosts) {
        this.setState({
          installationAmount: '150',
        })
      }
      if (!withPresentation || installationCosts) {
        this.setState({
          installationAmount: '100',
        })
      }
    }
    if ((!_.isEqual(installationCosts, installationCostsOld) || !_.isEqual(subscription, subscriptionOld))) {
      if (installationCosts && !subscription) {
        this.setState({
          onlyCgv: true,
        })
      } else {
        this.setState({
          onlyCgv: false,
        })
      }
    }
    if (!_.isEqual(project, projectOld)) {
      this.setState({
        plan: stripe.filter((f) => f.name === project)[0].plans[0].id,
      })
    }
  }
  
  handleAddUser = () => {
    const { Users } = this.state
    const array = Users
    array.push({ id: Users.length + 1, Role: 'Utilisateur' })
    this.setState({ Users: array })
  }

  handleNameChange = (e, idx) => {
    const { Users } = this.state
    const nextUsers = Users.slice()
    nextUsers[idx].name = e.target.value
    this.setState({ Users: nextUsers })
  }

  handleEmailChange = (e, idx) => {
    const { Users } = this.state
    const nextUsers = Users.slice()
    nextUsers[idx].email = e.target.value
    this.setState({ Users: nextUsers })
  }

  handleRoleChange = (Role, idx) => {
    const { Users } = this.state
    const nextUsers = Users.slice()
    nextUsers[idx].Role = Role
    this.setState({ Users: nextUsers })
  }

  handleRemoveUsers = (idx) => {
    const { Users } = this.state
    const someArray = Users
    someArray.splice(idx, 1)
    this.setState({ Users: someArray })
  }

  handleChange = (e) => {
    const { installationCosts, onlyCgv } = this.state
    if (e.target.name === 'installationCosts' || e.target.name === 'onlyCgv') {
      if (e.target.name === 'installationCosts') {
        this.setState({
          installationCosts: !installationCosts,
        })
      }
      if (e.target.name === 'onlyCgv') {
        this.setState({
          onlyCgv: !onlyCgv,
        })
      }
    } else if (e.target.name === 'Name') {
      this.setState({
        Name: e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1),
      })
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      })
    }
  }

  handleOptionChange = (e) => {
    const { installationCosts, withPresentation, subscription } = this.state
    if (e.target.name === 'installationCosts') {
      this.setState({
        [e.target.name]: !installationCosts,
      })
    }
    if (e.target.name === 'withPresentation') {
      this.setState({
        [e.target.name]: !withPresentation,
      })
    }
    if (e.target.name === 'subscription') {
      this.setState({
        [e.target.name]: !subscription,
      })
    }
  }

  closeSnacks = (e, name) => { this.setState({ [name]: false }) }

  saveAndSendMail = (e) => {
    const {
      Compagny,
      Email,
      Name,
      Phone,
      Postal,
      City,
      Users,
      checked,
      project,
      plan,
      installationCosts,
      onlyCgv,
      subscription,
      installationAmount,
      withPresentation,
    } = this.state
    const { match, history } = this.props
    const { id: idUrl } = match.params
    const Quantity = Users.length

    e.preventDefault()
    if (checked && idUrl === undefined) {
      firebase.database.ref('request').push({
        compagny: Compagny,
        email: Email,
        project: project,
        plan: plan,
        id: Math.random().toString(36).substr(2, 9),
        name: Name,
        phone: Phone,
        postal: Postal,
        city: City,
        quantity: Quantity,
        users: Users,
        status: false,
        paid: '/',
        cgv: false,
        dateCgv: '/',
        date: moment().format('DD/MM/YYYY'),
        installationCosts: installationCosts,
        installationAmount: installationAmount,
        onlyCgv: onlyCgv,
        subscription: subscription,
        withPresentation: withPresentation,
      })
        .then(async (response) => {
          if (response.status === 500) {
            this.setState({
              error: {
                operation: true,
                message: "Il y a eu une erreur lors de l'enregistrement",
              },
            })
          }
          fetch(`${process.env.REACT_APP_BASE_URL}/subscriptionConfirmation`, {
            method: 'POST',
            headers: new Headers({
              authorization: `Bearer ${await firebase.auth.currentUser.getIdToken()}`,
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
              customerEmail: Email,
              project: project,
              url: `?id=${response.key}`,
            }),
          })
            .then((resp) => {
              if (resp.status === 500) {
                this.setState({
                  error: {
                    operation: true,
                    message: "Il y a eu une erreur lors de l'envoi",
                  },
                })
              }
              this.setState({
                success: {
                  operation: true,
                  message: 'Email envoyé au client !',
                },
              })
              setTimeout(() => {
                history.push('/')
              }, 400)
            })
        })
    }

    if (checked && idUrl !== undefined) {
      firebase.database.ref(`request/${idUrl}`).update({
        compagny: Compagny,
        email: Email,
        project: project,
        name: Name,
        phone: Phone,
        postal: Postal,
        city: City,
        quantity: Quantity,
        users: Users,
      })
        .then(() => {
          this.setState({
            success: {
              operation: true,
              message: 'Sauvegardé avec succès',
            },
          })
        })
    }
  }

  displayMultiplesInputs = () => {
    const { Users, Roles } = this.state
    return (
      Users.map((User, idx) => (
        <div key={idx} style={{ display: 'flex', alignItems: 'center', marginBottom: 15 }}>
          <TextField
            type="text"
            required
            placeholder="Nom & prénom"
            value={User.name}
            style={{ marginRight: 15, width: '33%' }}
            onChange={(e) => this.handleNameChange(e, idx)}
          />
          <TextField
            type="email"
            required
            placeholder="Email"
            value={User.email}
            style={{ marginRight: 15, width: '33%' }}
            onChange={(e) => this.handleEmailChange(e, idx)}
          />
          <Select
            onChange={(e) => {
              this.handleRoleChange(e.target.value, idx);
            }}
            required
            style={{ marginRight: 15, width: '33%' }}
            value={User.Role}
          >
            {Roles.map((opt) => (
              <MenuItem value={opt.name} data={opt} key={opt.id}>
                {opt.name}
              </MenuItem>
            ))}
          </Select>
          <IconButton onClick={() => this.handleRemoveUsers(idx)}>
            <DeleteIcon />
          </IconButton>
        </div>
      ))
    )
  }

  displayInformations = () => {
    const {
      checked,
      Date: Demande,
      Paid,
      Status,
      cgv,
      dateCgv,
    } = this.state
    const { match } = this.props
    const { id } = match.params
    if (checked && id === undefined) return null
    if (checked && id !== undefined) {
      return (
        <div>
          <h3>Demande de déploiement</h3>
          <div style={{ display: 'flex', justifyContent: 'space-between', margin: 15 }}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            >
              <h4>Date de demande</h4>
              <span>{Demande}</span>
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            >
              <h4>Statut</h4>
              <span>{!Status ? 'Envoyé' : 'Complété'}</span>
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            >
              <h4>Date de paiement</h4>
              <span>{Paid === null ? '/' : Paid}</span>
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            >
              <h4>CGV</h4>
              <span>{!cgv ? '/' : dateCgv}</span>
            </div>
          </div>
        </div>
      )
    }
    return null
  }

  displayOptions = () => {
    const { match } = this.props
    const { id } = match.params
    const {
      installationCosts,
      installationAmount,
      withPresentation,
      subscription,
    } = this.state
    const displayInstallationAmount = () => {
      if (!installationCosts && id === undefined) {
        return (
          <TextField
            type="number"
            className="input"
            style={{ width: '100%' }}
            name="installationAmount"
            label={withPresentation && !installationCosts ? 'Frais de MEP + présentation HT' : 'Frais de mise en place HT'}
            value={installationAmount}
            onChange={this.handleChange}
            required
          />
        )
      }
      return null
    }
    return (
      <div>
        <h3>Tarification</h3>
        <FormControlLabel
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            margin: 0,
            width: 'fit-content',
          }}
          control={(
            <Checkbox
              checked={subscription}
              onChange={this.handleOptionChange}
              name="subscription"
              disabled={id !== undefined}
            />
          )}
          labelPlacement="end"
          label="Facturation de l'abonnement à l'outil"
        />
        <FormControlLabel
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            margin: 0,
            width: 'fit-content',
          }}
          control={(
            <Checkbox
              checked={!installationCosts}
              onChange={this.handleOptionChange}
              name="installationCosts"
              disabled={id !== undefined}
            />
          )}
          labelPlacement="end"
          label="Frais de mise en place"
        />
        <FormControlLabel
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            margin: 0,
            width: 'fit-content',
          }}
          control={(
            <Checkbox
              checked={withPresentation}
              onChange={this.handleOptionChange}
              name="withPresentation"
              disabled={id !== undefined}
            />
          )}
          labelPlacement="end"
          disabled={installationCosts || id !== undefined}
          label="Présentation de l'outil"
        />
        {displayInstallationAmount()}
      </div>
    )
  }

  displaySubmit = () => {
    const {
      checked,
      Users,
      installationCosts,
      subscription,
    } = this.state
    const { match } = this.props
    const { id } = match.params
    let Quantity = true
    if (Users.length > 0) Quantity = false

    if (checked && id === undefined) {
      return (
        <div>
          <Button disabled={Quantity} type="submit" variant="contained" color="secondary">
            {installationCosts && !subscription ? 'Envoyer les CGV au client' : 'Envoyer le lien au client'}
          </Button>
        </div>
      )
    }
    if (checked && id !== undefined) {
      return (
        <div>
          <Button disabled={Quantity} type="submit" variant="contained" color="secondary">
            Sauvegarder
          </Button>
        </div>
      )
    }
    return null
  }

  createForm = () => {
    const {
      Compagny,
      Email,
      Name,
      Phone,
      Postal,
      City,
      success,
      error,
      project,
      Users,
      stripe,
      plan,
    } = this.state
    const { history, match } = this.props
    const { id } = match.params
    const goBack = () => { history.push('/') }
    return (
      <form onSubmit={this.saveAndSendMail}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={goBack} style={{ marginRight: 8, cursor: 'pointer' }}>
            <ArrowBackIcon />
          </IconButton>
          <h2>{`${Name === '' ? 'Nouvelle' : ''} ${Name === '' ? 'demande' : 'Demande'} ${Name === '' ? '' : `de ${Name}`}`}</h2>
        </div>
        {this.displayInformations()}
        <h3>Informations clients</h3>
        <TextField className="input" name="Compagny" label="Raison sociale" value={Compagny} onChange={this.handleChange} required />
        <TextField className="input" name="Email" label="Email contact" value={Email} onChange={this.handleChange} required />
        <TextField className="input" name="Name" label="Nom & prénom" value={Name} onChange={this.handleChange} required />
        <TextField className="input" name="Phone" type="tel" label="Téléphone" value={Phone} onChange={this.handleChange} required />
        <TextField className="input" name="Postal" type="number" label="Code postal" value={Postal} onChange={this.handleChange} required />
        <TextField className="input" name="City" label="Ville" value={City} onChange={this.handleChange} required />
        <InputLabel id="project">Produit</InputLabel>
        <Select
          labelId="project"
          name="project"
          value={project}
          onChange={this.handleChange}
          disabled={id !== undefined}
        >
          {
            id !== undefined ? (
              <MenuItem value={project}>{project}</MenuItem>
            ) : null
          }
          {
            id === undefined ? stripe.map((item, i) => {
              if (item.active) {
                return <MenuItem key={i} value={item.name}>{item.name}</MenuItem>
              }
              return null
            }) : null
          }
        </Select>
        {
          id !== undefined ? (
            <>
              <InputLabel style={{ marginTop: 16 }} id="plan">Plan tarifaire</InputLabel>
              <Select
                labelId="plan"
                name="plan"
                value={plan}
                onChange={this.handleChange}
                disabled={id !== undefined}
              >
                {
                  id !== undefined ? <MenuItem value={plan}>{stripe.filter((i) => i.name === project)[0].plans.filter((j) => j.id === plan)[0].amount} / mois</MenuItem> : null
                }
              </Select>
            </>
          ) : null
        }
        {
          id === undefined ? stripe.filter((f) => f.name === project)[0].plans.length > 1
            ? (
              <>
                <InputLabel style={{ marginTop: 16 }} id="plan">Plan tarifaire</InputLabel>
                <Select
                  labelId="plan"
                  name="plan"
                  value={plan}
                  onChange={this.handleChange}
                >
                  {
                    id === undefined ? stripe.filter((f) => f.name === project)[0].plans.map((activePlan) => <MenuItem value={activePlan.id}>{activePlan.amount} / mois</MenuItem>) : null
                  }
                </Select>
              </>
            )
            : (
              null
            )
            : null
        }
        <div style={{ display: 'flex' }}>
          <h3 style={{ marginRight: 20, width: '100%' }}>{`${Users.length === 0 ? "Nombre d'utilisateurs / licenses" : `Nombre d'utilisateurs: ${Users.length}`}`}</h3>
          <IconButton style={{ width: 50, height: 50 }} type="button" onClick={this.handleAddUser}>
            <AddIcon />
          </IconButton>
        </div>
        {this.displayMultiplesInputs()}
        {this.displayOptions()}
        {this.displaySubmit()}
        <Snackbar open={success.operation} autoHideDuration={5000} onClose={(e) => this.closeSnacks(e, 'success')}>
          <MuiAlert onClose={this.closeSnacks} everity="success" elevation={6} variant="filled">
            {success.message}
          </MuiAlert>
        </Snackbar>
        <Snackbar open={error.operation} autoHideDuration={5000} onClose={(e) => this.closeSnacks(e, 'error')}>
          <MuiAlert onClose={this.closeSnacks} everity="error" elevation={6} variant="filled">
            {error.message}
          </MuiAlert>
        </Snackbar>
      </form>
    )
  }

  render() {
    const {
      checked,
      stripe,
    } = this.state
    if (!checked || _.isEmpty(stripe)) return <h2>Chargement...</h2>
    return (
      <div className="Request"> 
        {this.createForm()}
      </div>
    )
  }
}

export default CustomerRequest
