import {
  SET_USER,
  LOGOUT,
} from './actions'

const initialState = {
  user: {},
  token: '',
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return {
        user: { ...action.user },
        token: action.token,
      }
    case LOGOUT:
      return { ...initialState }
    default:
      return state
  }
}
