import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import { confirmAlert } from 'react-confirm-alert'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import firebase from '../../database/config'

export default class CustomerTracking extends React.PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      trackingList: null,
      originalList: null,
      error: {
        operation: false,
        message: '',
      },
      success: {
        operation: false,
        message: '',
      },
      search: '',
    }
  }

  componentDidMount() {
    const { history } = this.props
    firebase.auth.onAuthStateChanged((user) => {
      if (user) {
        const trackingRef = firebase.database.ref('request')
        trackingRef.on('value', (snap) => {
          const trackingList = snap.val() || []
          if (trackingList !== null || trackingList !== undefined) {
            const keys = Object.keys(trackingList)
            const data = []
            keys.map((i) => data.push({ ...trackingList[i], url: i }))
            this.setState({
              originalList: data,
              trackingList: data.reverse(),
              loading: false,
            })
          }
        })
      } else {
        history.replace('/login')
      }
    })
  }

  selectCustomer = (e, id) => {
    const { history } = this.props
    history.push(`/tracking/${id}`)
  }

  closeSnacks = (e, name) => { this.setState({ [name]: false }) }

  removeItem = (e, id, name) => {
    const confirm = () => {
      firebase.database.ref(`request/${id}`).remove()
        .then(() => {
          this.setState({
            success: {
              operation: true,
              message: 'Élément supprimé',
            },
          })
        })
        .catch(() => {
          this.setState({
            error: {
              operation: true,
              message: 'Il y a eu une erreur lors de la suppression',
            },
          })
        })
    }
    confirmAlert({
      message: `Êtes-vous sûr de vouloir supprimer la demande de ${name} ?`,
      buttons: [
        { label: 'Annuler' },
        {
          label: 'Confirmer',
          onClick: confirm,
        },
      ],
    })
  }

  search = () => {
    const { search, originalList } = this.state
    let currentList = []
    let newList = []

    if (search !== '') {
      currentList = originalList

      newList = currentList.filter((item) => {
        const lc = item.name.toLowerCase()
        const filter = search.toLowerCase()

        return lc.includes(filter)
      })
    } else {
      newList = originalList
    }
    this.setState({
      trackingList: newList,
    })
    return true
  }

  handleChange = (e) => {
    e.preventDefault()
    this.setState({
      search: e.target.value.toLowerCase(),
    }, this.search)
  }

  display = () => {
    const { history } = this.props
    const {
      trackingList,
      error,
      success,
      search,
      loading,
    } = this.state
    const create = () => history.push('/tracking')
    if (trackingList === null && loading) return <h2>Chargement...</h2>
    if (trackingList === null && !loading) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h2>Aucune demande</h2>
          <Button onClick={create} variant="contained" color="secondary">
            NOUVELLE DEMANDE
          </Button>
        </div>
      )
    }
    if (trackingList !== null && _.isEmpty(trackingList) && search === '') {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h2>Aucune demande</h2>
          <Button onClick={create} variant="contained" color="secondary">
            NOUVELLE DEMANDE
          </Button>
        </div>
      )
    }
    if ((trackingList !== null && !_.isEmpty(trackingList)) || (_.isEmpty(trackingList) && search !== '')) {
      return (
        <div className="Tracking">
          <main className="root">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ width: 'fit-content' }}>
                <TextField
                  type="text"
                  placeholder="Rechercher par client"
                  name="search"
                  value={search}
                  onChange={this.handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div style={{ marginBottom: 20, width: 'fit-content' }}>
                <Button variant="contained" color="secondary" onClick={create}>
                  NOUVELLE DEMANDE
                </Button>
              </div>
            </div>
            {
              search !== '' && _.isEmpty(trackingList)
                ? (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h2>Aucun résultat</h2>
                  </div>
                )
                : (
                  <TableContainer component={Paper}>
                    <Table className="table" aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell />
                          <TableCell align="center">Date demande</TableCell>
                          <TableCell align="center">Produit</TableCell>
                          <TableCell align="center">Entreprise</TableCell>
                          <TableCell align="center">Nom & prénom</TableCell>
                          <TableCell align="center">Nb utilisateurs</TableCell>
                          <TableCell align="center">Statut</TableCell>
                          <TableCell align="center">Date de paiement</TableCell>
                          <TableCell align="center">CGV</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {trackingList.map((row) => (
                          <TableRow className="row" key={row.id}>
                            <TableCell padding="checkbox">
                              <IconButton style={{ width: 50, height: 50 }} type="button" onClick={(e) => this.removeItem(e, row.url, row.name)}>
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                            <TableCell onClick={(e) => this.selectCustomer(e, row.url)} align="center">{row.date}</TableCell>
                            <TableCell onClick={(e) => this.selectCustomer(e, row.url)} align="center">{row.project}</TableCell>
                            <TableCell onClick={(e) => this.selectCustomer(e, row.url)} align="center">{row.compagny}</TableCell>
                            <TableCell onClick={(e) => this.selectCustomer(e, row.url)} align="center">{row.name}</TableCell>
                            <TableCell onClick={(e) => this.selectCustomer(e, row.url)} align="center">{row.quantity}</TableCell>
                            <TableCell onClick={(e) => this.selectCustomer(e, row.url)} align="center">{row.status === false ? 'Envoyé' : 'Complété'}</TableCell>
                            <TableCell onClick={(e) => this.selectCustomer(e, row.url)} align="center">{row.paid === undefined ? '/' : row.paid}</TableCell>
                            <TableCell onClick={(e) => this.selectCustomer(e, row.url)} align="center">{row.cgv === false ? '/' : row.dateCgv}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )
            }
            
          </main>
          <Snackbar open={error.operation} autoHideDuration={5000} onClose={(e) => this.closeSnacks(e, 'error')}>
            <MuiAlert onClose={this.closeSnacks} everity="error" elevation={6} variant="filled">
              {error.message}
            </MuiAlert>
          </Snackbar>
          <Snackbar open={success.operation} autoHideDuration={5000} onClose={(e) => this.closeSnacks(e, 'success')}>
            <MuiAlert onClose={this.closeSnacks} everity="success" elevation={6} variant="filled">
              {success.message}
            </MuiAlert>
          </Snackbar>
        </div>
      )
    }
    return null
  }

  snapshotToArray(snapshot) {
    const returnArr = []

    snapshot.forEach((childSnapshot) => {
      const item = childSnapshot.val()
      item.key = childSnapshot.key

      returnArr.push(item);
    })
    return returnArr
  }

  render() {
    return this.display()
  }
}
