import React from 'react'
import {
  Paper, withStyles, Grid, TextField, Button, FormControlLabel, Checkbox, 
} from '@material-ui/core'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Face, Fingerprint } from '@material-ui/icons'
import { connect } from 'react-redux'
import firebase from '../../database/config'

const styles = (theme) => ({
  margin: {
    margin: theme.spacing.unit * 2,
  },
  padding: {
    padding: theme.spacing.unit,
    width: 450,
  },
})

class Login extends React.PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    SET_USER: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      Name: '',
      Password: '',
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  onSubmit = (e) => {
    e.preventDefault()
    const { Name, Password } = this.state
    const {
      SET_USER, history, location: { state },
    } = this.props
    // fetch(`${process.env.REACT_APP_BASE_URL}/signin`, {
    //   method: 'POST',
    //   headers: new Headers({
    //     'Content-Type': 'application/json',
    //   }),
    //   body: JSON.stringify(this.state),
    // })
    //   .then((res) => {
    //     if (res.status === 500) {
    //       alert('Erreur serveur')
    //     }
    //     if (res.status === 401) {
    //       alert('Mauvais mot de passe ou adresse email')
    //     }
    //     if (res.status === 200) {
    //       alert('Connecté')
    //       return res.json()
    //     }
    //     return true
    //   })
    //   .then((data) => {
    //     if (!_.isEmpty(data)) {
    //       SET_USER(data.user, data.token)
    //       localStorage.setItem('bo-ndf-token', data.token)
    //       const { activeTab, from } = state || { from: { pathname: '/' } }
    //       history.push({
    //         pathname: from.pathname,
    //         state: { activeTab },
    //       })
    //     }
    //   })

    firebase.auth.signInWithEmailAndPassword(Name, Password)
      .then(() => {
        const { activeTab, from } = state || { from: { pathname: '/' } }
        history.push({
          pathname: from.pathname,
          state: { activeTab },
        })
      })
      .catch((error) => {
        alert('Mauvais mot de passe ou adresse email')
        console.log(error)
      })
  }

  render() {
    const { classes } = this.props
    return (
      <form onSubmit={this.onSubmit}>
        <Paper className={classes.padding}>
          <div className={classes.margin}>
            <Grid container spacing={8} alignItems="flex-end">
              <Grid item>
                <Face />
              </Grid>
              <Grid item md sm xs>
                <TextField onChange={this.handleChange} name="Name" label="Adresse email" type="email" fullWidth autoFocus required />
              </Grid>
            </Grid>
            <Grid container spacing={8} alignItems="flex-end">
              <Grid item>
                <Fingerprint />
              </Grid>
              <Grid item md sm xs>
                <TextField onChange={this.handleChange} name="Password" label="Mot de passe" type="password" fullWidth required />
              </Grid>
            </Grid>
            <Grid container alignItems="center" justify="space-between">
              <Grid item>
                <FormControlLabel
                  control={(
                    <Checkbox
                      color="primary"
                    />
                                )}
                  label="Se rappeler de moi"
                />
              </Grid>
              <Grid item>
                <Button disableFocusRipple disableRipple style={{ textTransform: 'none' }} variant="text" color="primary">Mot de passe oublié ?</Button>
              </Grid>
            </Grid>
            <Grid container justify="center" style={{ marginTop: '10px' }}>
              <Button type="submit" variant="outlined" color="primary" style={{ textTransform: 'none' }}>Connexion</Button>
            </Grid>
          </div>
        </Paper>
      </form>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  SET_USER: (user, token) => dispatch({ type: 'SET_USER', user, token }),
})

export default connect(null, mapDispatchToProps)(withStyles(styles)(Login))
